import './gallery_image_contents.css';
import React, {useEffect, useRef} from 'react';
import { motion, useInView, useAnimation } from "framer-motion";
import VenoBox from 'venobox';
import 'venobox/dist/venobox.css';




const animation_variant = {
    "hidden" : {
        y : 100,
    },
    "visible" : {
        y : 0,
        transition : {
            duration: 0.25,
            delay: 0.25,
            yoyo: Infinity, 
            ease: "easeInOut",
        }
    }
}




const convertTimtToText = (isoString)=>{
    const date = new Date(isoString);
    const humanReadable = date.toLocaleString('en-US', {
        weekday: 'long', // long format for the day of the week (e.g., "Monday")
        year: 'numeric', // numeric format for the year
        month: 'long', // long format for the month (e.g., "January")
        day: 'numeric', // numeric format for the day
        hour: 'numeric', // numeric format for the hour
        minute: 'numeric', // numeric format for the minute
    });

    return humanReadable;
}



const GalleryPostCard = ({gallery_post_data, is_authenticated, delete_callback}) => {
    const hook_reference = useRef(null);
    const animation_control = useAnimation();
    const is_in_view = useInView(hook_reference, {once: false});

    const deleteCall = ()=>{
        delete_callback(gallery_post_data.id)
    }


    useEffect(()=>{
        if(is_in_view){
            animation_control.start("visible");
        }

        new VenoBox({
            selector: '.my-image-links',
            numeration: true,
            infinigall: true,
            share: true,
            spinner: 'rotating-plane'
        });

    }, [hook_reference, animation_control, is_in_view]);


    return ( 
        <div>
            <motion.div 
                className="bg-stone-50 rounded-lg d-flex flex-column align-items-center justify-content-center"
                variants = {animation_variant}
                initial = "hidden"
                animate = {animation_control}
                ref = {hook_reference}
            >
    
         
                <a className="my-image-links d-flex flex-row align-items-center justify-content-center main_image_container rounded-lg" data-gall="gallery01" href={gallery_post_data.image_url}>
                    <img className="shadow-1-strong rounded-lg mb-4 gallery_image" src={gallery_post_data.image_url} alt="gallery content"/>
                </a>
             
                <p className="mt-5 fw-light image_timestamp">{ convertTimtToText(gallery_post_data.created_at ) }</p>
                {   
                    is_authenticated 
                    && 
                    <button className="btn btn-sm btn-danger mt-2" onClick={deleteCall}>
                        <span className="material-symbols-outlined">delete</span>
                    </button>
                }
            </motion.div>
        </div>
     );
}
 
export default GalleryPostCard;